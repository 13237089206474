import React, {useEffect, useState} from 'react';
import Loader from "../../Chat/components/utils/Loader";
import { bookToFullImgSrc, toTitleCase, americanToDec } from "../../Chat/components/utils/utils";
import cashImg from "../../../images/pcash.svg";
import nvgCashImg from "../../../images/novigcash.png";

import pxLogoBlack from "../../../images/pxtextblack.svg";
import nvgLogoBlack from "../../../images/nvgtextblack.svg";
import trophyImg from "../../../images/trophy.png";


const Play = ({play}) => {
    const isWin = play.status === 'win';
    const isLoss = play.status === 'loss';
    const isPending = play.status === 'pending';

    const computePayout = (odds, stake) => {
        const decOdds = americanToDec(odds);
        const payout = stake * decOdds;
        return payout.toFixed(2);
    }

    const genShareUrl = () => {
        const atBookie = play.bookie === 'prophetx' ? '@PlayProphetX' : '@novig_app';
        const odds = play.odds > 0 ? '%2B' + play.odds : play.odds;
        return `https://twitter.com/intent/tweet?text=${play.eventTitle}%0A${play.marketTitle}%0A${play.outcomeTitle}%0A${odds}%20on%20${atBookie}%0A%0A&via=BeeBettor`;
    }

    return (
        <div key={play.id} className="flex flex-col border-half m-2 bg-white aspect-square max-w-[95%] w-96 h-fit hover:shadow-lg hover:scale-[1.01]">
            <div className="h-12 bg-gray-100 pl-2 border-b flex flex-row justify-between items-center">
                <div className="flex flex-row gap-1 items-center">
                    {play.bookie === 'prophetx' && <img src={pxLogoBlack} alt="ProphetX" height={24} width={92} />}
                    {play.bookie === 'novig' && <img src={nvgLogoBlack} alt="Novig" height={24} width={96} />}
                    <a className="cursor-pointer text-xs text-gray-600 font-medium underline" href={genShareUrl()} target="_blank">Share</a>
                </div>
                {isWin && play.bookie === 'prophetx' && <div className="bg-[#75CBA8] h-full rounded-l-3xl py-2 pl-2 pr-1"><img src={trophyImg} alt="Trophy" height={32} width={32} /></div>}
                {isWin && play.bookie === 'novig' && <div className="bg-[#7dcefd] h-full rounded-l-3xl py-2 pl-2 pr-1"><img src={trophyImg} alt="Trophy" height={32} width={32} /></div>}
            </div>
            <div className="bg-gray-50 flex flex-col gap-2 px-2 py-2">
                <div className="flex flex-row justify-between">
                    <p className="text-sm text-gray-700 font-medium">{play.marketTitle}</p>
                    <p className="font-bold">{play.odds > 0 ? '+' + play.odds : play.odds}</p>
                </div>

                <div className="flex flex-row gap-2 items-center px-2">
                    {isWin && <span className="material-icons" style={{fontSize: '24px', color: '#22C55E'}}>check_circle</span>}
                    {isLoss && <span className="material-icons" style={{fontSize: '24px', color: '#EF4444'}}>cancel</span>}
                    {isPending && <span className="material-icons" style={{fontSize: '24px', color: '#808080'}}>radio_button_unchecked</span>}
                    <p className="text-sm text-black font-bold text-lg">{toTitleCase(play.outcomeTitle)}</p>
                </div>

                <div className="mt-4 flex flex-row justify-between gap-1">
                    <p className="font-medium text-gray-600 text-xs">{play.eventTitle}</p>
                    <p className="text-xs text-gray-600">{new Date(play.eventTs).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true
                    })}</p>
                </div>
            </div>


            <div className="bg-gray-200 border-t flex flex-row justify-between items-center p-2">
                <div className="flex flex-row gap-1 items-center">
                    {play.bookie === 'prophetx' && <img src={cashImg} alt="cash" height={32} width={32} className={isLoss ? 'grayscale' : ''} />}
                    {play.bookie === 'novig' && <img src={nvgCashImg} alt="cash" height={24} width={24} className={isLoss ? 'grayscale' : ''} />}
                    <div className={`text-gray-600 font-semibold ${isLoss ? 'line-through' : ''}`}>{play.stake.toFixed(2)}</div>
                </div>
                <div className="">

                    {isWin && <div className="flex flex-row gap-1 items-center">
                        {play.bookie === 'prophetx' && <img src={cashImg} alt="cash" height={32} width={32} className={isLoss ? 'grayscale' : ''} />}
                        {play.bookie === 'novig' && <img src={nvgCashImg} alt="cash" height={24} width={24} className={isLoss ? 'grayscale' : ''} />}
                        <p className="text-lg text-green-500 font-black">{computePayout(play.odds, play.stake)}</p>
                    </div>}

                    {isPending && 
                        <div className="flex flex-col items-end">
                            <p className="text-xs text-gray-500">To Win</p>
                            <div className="flex flex-row gap-1 items-center">
                                {play.bookie === 'prophetx' && <img src={cashImg} alt="cash" height={32} width={32} className="" />}
                                {play.bookie === 'novig' && <img src={nvgCashImg} alt="cash" height={24} width={24} className="" />}
                                <p className="text-lg text-black font-bold">{computePayout(play.odds, play.stake)}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

const History = () => {
    const [isLoadingHeader, setIsLoadingHeader] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(true);
    const [plays, setPlays] = useState([]);

    const [isPxConnected, setIsPxConnected] = useState(false);
    const [isNvgConnected, setIsNvgConnected] = useState(false);

    const [pxBalance, setPxBalance] = useState(null);
    const [nvgBalance, setNvgBalance] = useState(null);

    useEffect(() => {
        fetch('/profile/accounts/connected', {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setIsPxConnected(data.prophetx);
            setIsNvgConnected(data.novig);
            if (data.prophetx || data.novig) {
                getBalance();
                getPlays();
            } else {
                setIsLoadingHeader(false);
                setIsLoading(false);
            }
        }).catch(err => {
            console.error(err);
            // alert(`Error ${statusCode} occurred.`);
        });
    }, []);

    useEffect(() => {
        if (!isLoadingHeader) {
            getPlays();
        }
    }, [open]);

    const getBalance = () => {
        fetch('/wager/orderbook/balances', {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            setPxBalance(data.prophetx);
            setNvgBalance(data.novig);
            setIsLoadingHeader(false);
        }).catch(err => {
            console.error(err);
            // alert(`Error ${statusCode} occurred.`);
        });
    }

    const getPlays = () => {
        setPlays([]);
        setIsLoading(true);
        fetch(`/wager/orderbook/plays?is_open=${open}`, {
            'headers': {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
            }
        }).then(res => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        }).then(data => {
            if (data.success) {
                console.log(data);
                setPlays(data.plays);
            }
            setIsLoading(false);
            // setHasNovigBeta(data.valid);
        }).catch(err => {
            console.error(err);
            const statusCode = err.status;
            // alert(`Error ${statusCode} occurred.`);
        });
    }

    const renderHeader = () => {
        if (isLoadingHeader) {
            return <div data-join-target="loader" role="status" className="w-fit h-full flex items-center">
                <svg aria-hidden="true" className={`w-8 h-8 mx-auto text-transparent animate-spin fill-yellow-300`}
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"/>
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        }
        // if (balance === null) {
        //     return <div className="text-gray-600">Loading...</div>;
        // }
        return (
            <div className="flex flex-row gap-4 items-center">
                {isPxConnected &&
                    <div className="flex flex-col gap-0 border-2 rounded-lg p-2">
                        <p className="text-gray-500 text-xs">ProphetX Balance</p>
                        <div className="flex flex-row gap-1 items-center">
                            <img src={cashImg} alt="cash" height={32} width={32} />
                            <div className="text-gray-600 font-bold">{pxBalance}</div>
                        </div>
                    </div>
                }
                {!isPxConnected &&
                    <a href="/profile/accounts">
                        <div className="hover:bg-gray-100 cursor-pointer flex flex-col gap-0 border-2 border-purple-500 rounded-lg p-2">
                            <div className="flex flex-row gap-1 items-center">
                                <img src={cashImg} alt="cash" height={32} width={32} />
                                <div className="text-gray-600 font-bold">Connect to ProphetX</div>
                            </div>
                        </div>
                    </a>
                }
                {isNvgConnected &&
                    <div className="flex flex-col gap-0 border-2 rounded-lg p-2">
                        <p className="text-gray-500 text-xs">Novig Balance</p>
                        <div className="flex flex-row gap-1 items-center">
                            <img src={nvgCashImg} alt="cash" height={24} width={24} />
                            <div className="text-gray-600 font-bold">{nvgBalance}</div>
                        </div>
                    </div>
                }
                {!isNvgConnected &&
                    <a href="/profile/accounts">
                        <div className="hover:bg-gray-100 cursor-pointer flex flex-col gap-0 border-2 border-purple-500 rounded-lg p-2">
                            <div className="flex flex-row gap-1 items-center">
                                <img src={nvgCashImg} alt="cash" height={24} width={24} />
                                <div className="text-gray-600 font-bold">Connect to Novig</div>
                            </div>
                        </div>
                    </a>
                }
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 mb-4 min-h-screen">
            <div className="flex flex-row justify-between items-center pl-4 pr-12">
                <div className="flex flex-col gap-1 md:text-left text-center">
                    <h1 className="mx-auto md:mx-0 flex flex-row text-center md:text-left"><span className="font-semibold text-4xl">Orders</span></h1>
                    <h2 className="text-gray-600">View your order history from <a href="https://www.prophetx.co" target="_blank" rel="noopener noreferrer" className="underline">ProphetX</a> and <a href="https://www.novig.us" target="_blank" rel="noopener noreferrer" className="underline">Novig</a>.</h2>
                </div>

                {renderHeader()}
            </div>
            <hr/>

            <div className="px-4">
                <div className="border flex flex-row rounded-lg w-48 h-fit">
                    <button 
                        onClick={() => setOpen(true)}
                        className={`h-fit w-1/2 cursor-pointer text-center py-2 font-bold rounded-lg ${open ? 'bg-yellow-300 hover:bg-yellow-400' : 'hover:bg-gray-100'}`}
                    >
                        Open
                    </button>

                    <button
                        onClick={() => setOpen(false)}
                        className={`h-fit w-1/2 cursor-pointer text-center py-2 font-bold rounded-lg ${!open ? 'bg-yellow-300 hover:bg-yellow-400' : 'hover:bg-gray-100'}`}
                    >
                        Closed 
                    </button>
                </div>
            </div>

            <div className={`h-full flex flex-row pb-8 ${(isLoading || plays.length === 0) ? 'items-center flex-1' : ''}`}>
                {isLoading && <Loader />}
                {!isLoading && !isPxConnected && !isNvgConnected &&
                    <div className="flex flex-col items-center gap-4 w-full">
                        <span className="material-icons" style={{fontSize: '96px', color: 'gray'}}>power_off</span>
                        <p className="text-gray-600">Connect to ProphetX or Novig to view your order history.</p>
                    </div>
                }
                {!isLoading && (isPxConnected || isNvgConnected) && plays.length === 0 &&
                    <div className="flex flex-col items-center gap-4 w-full">
                        <span className="material-icons" style={{fontSize: '96px', color: 'gray'}}>search_off</span>
                        <p className="text-gray-600">No orders found.</p>
                    </div>
                }
                <div className="flex flex-wrap justify-center">
                    {!isLoading && plays.length > 0 && plays.map((play) => <Play key={play.id} play={play} />)}
                </div>
            </div>
        </div>
    )
}



// autoSettled
// : 
// null
// averageOdds
// : 
// "+100"
// children
// : 
// []
// commission
// : 
// 0
// createdAt
// : 
// "2024-12-11T21:09:44+0000"
// displayLine
// : 
// "12.5"
// displayOdds
// : 
// "+100"
// id
// : 
// 64430758
// isHotData
// : 
// false
// line
// : 
// "12.5"
// market
// : 
// {id: 150001853, name: 'Josh Hart Total Points', type: 'total'}
// marketLineName
// : 
// "Josh Hart Total Points"
// matchedDetails
// : 
// [{…}]
// matchedStake
// : 
// 11.82
// matchingStatus
// : 
// "fully_matched"
// odds
// : 
// 100
// outcome
// : 
// {id: 12, name: 'over 12.5'}
// refId
// : 
// "partner_9745f15c-6749-4a66-8a4c-efa5c39afdbc"
// sport
// : 
// {name: 'Basketball'}
// sportEvent
// : 
// {id: 20022238, status: 'live', type: null, name: 'Atlanta Hawks at New York Knicks', displayName: '', …}
// stake
// : 
// 11.82
// status
// : 
// "open"
// totalBets
// : 
// 1
// totalStake
// : 
// 0
// tournament
// : 
// {name: 'NBA'}
// unmatchedStake
// : 
// 0
// winningStatus
// : 
// "tbd"

export default History;